import Maybe from "data.maybe";
import { Map } from "immutable";
import { compose, converge, either, filter, map, prop } from "ramda";
import { createSelector } from "reselect";
import { getOrElse, immutableGet, safeImmutableGet, toInt } from "utils";

export const selectAppDomain = immutableGet("app");
export const selectAuthDomain = immutableGet("auth");
export const selectSystemsDomain = immutableGet("systems");
export const selectDealerDomain = immutableGet("dealer");

export const selectSystemIdFromProps = (_, props) => props.systemId;

export const selectUsersByEmail = compose(
  prop("usersByEmail"),
  selectAuthDomain
);

export const selectUsersByCustomerId = createSelector(
  selectUsersByEmail,
  (users) => users.mapKeys((_, user) => Number(user.get("accessibleId")))
);

export const getServicesManager = immutableGet("servicesManager");
export const hasArmingAppEnabled = compose(
  immutableGet("armingAppEnabled"),
  getServicesManager
);
export const hasFullAppEnabled = compose(
  immutableGet("fullAppEnabled"),
  getServicesManager
);
export const getSystemsWithAppEnabled = filter(
  either(hasArmingAppEnabled, hasFullAppEnabled)
);
export const selectSystems = createSelector(selectSystemsDomain, (systems) =>
  getSystemsWithAppEnabled(systems.get("byId"))
);
export const selectActiveSystemId = compose(
  immutableGet("activeSystemId"),
  selectSystemsDomain
);
export const selectActiveSystem = converge(safeImmutableGet, [
  selectActiveSystemId,
  selectSystems,
]);
export const selectDefaultSystemId = createSelector(
  selectSystemsDomain,
  selectSystems,
  (substate, systems) =>
    substate.get("defaultSystemId") ||
    (!systems.isEmpty() ? systems.first().get("id") : null)
);

export const selectDefaultSystem = converge(safeImmutableGet, [
  selectDefaultSystemId,
  selectSystems,
]);

export const selectSystem = converge(safeImmutableGet, [
  selectSystemIdFromProps,
  selectSystems,
]);
export const selectSelectedCustomerIdForAdminSection = compose(
  prop("selectedCustomerForAdminSection"),
  selectAppDomain
);
export const selectSelectedCustomerForAdminSection = createSelector(
  selectSelectedCustomerIdForAdminSection,
  selectUsersByCustomerId,
  (customerId, usersByCustomerId) =>
    customerId && usersByCustomerId.get(customerId)
);
export const selectSelectedCustomerForAdminSectionIsAdmin = createSelector(
  selectSelectedCustomerForAdminSection,
  (user) => !!user?.role.toLowerCase() === "admin"
);
export const selectUser = createSelector(
  selectUsersByCustomerId,
  selectActiveSystem,
  selectSelectedCustomerIdForAdminSection,
  (usersByCustomerId, activeSystem, selectedCustomerIdForAdminSection) =>
    Maybe.fromNullable(activeSystem)
      .map(prop("customerId"))
      .orElse(() => Maybe.fromNullable(selectedCustomerIdForAdminSection))
      .orElse(() =>
        Maybe.fromNullable(usersByCustomerId.keySeq().toList().first())
      )
      .chain((customerId) =>
        Maybe.fromNullable(usersByCustomerId.get(customerId))
      )
);
export const selectCustomerNamesByCustomerId = createSelector(
  selectUsersByCustomerId,
  (usersByCustomerId) => usersByCustomerId.map(immutableGet("customerName"))
);
export const selectSelectedCustomerName = createSelector(
  selectSelectedCustomerIdForAdminSection,
  selectCustomerNamesByCustomerId,
  (customerId, customerNamesByCustomerId) =>
    customerNamesByCustomerId.get(customerId)
);
export const selectActiveUserAuthToken = compose(
  map(immutableGet("authToken")),
  selectUser
);

export const selectUserPermissions = createSelector(
  selectUser,
  compose(getOrElse(Map()), map(immutableGet("userPermissions")))
);

export const selectAuthTokenForSystem = (state, { systemId }) => {
  const usersByCustomerId = selectUsersByCustomerId(state);
  const system = selectSystem(state, { systemId });
  return system
    ? usersByCustomerId.getIn([system.customerId, "authToken"])
    : undefined;
};

export const selectAuthTokenForActiveSystem = (state) => {
  const usersByCustomerId = selectUsersByCustomerId(state);
  const activeSystem = selectActiveSystem(state);
  return activeSystem
    ? usersByCustomerId.getIn([activeSystem.customerId, "authToken"])
    : undefined;
};

export const selectAuthTokenForPerson = createSelector(
  selectUsersByCustomerId,
  (_, { personId }) => personId,
  (usersByCustomerId, personId) =>
    usersByCustomerId.find((user) => Number(user.personId) === Number(personId))
      ?.authToken
);

export const selectAuthTokenForUser = createSelector(
  selectUsersByCustomerId,
  (_, { userId }) => userId,
  (usersByCustomerId, userId) =>
    usersByCustomerId.find((user) => Number(user.id) === Number(userId))
      ?.authToken
);

export const selectAuthTokenForDealer = createSelector(
  selectUsersByCustomerId,
  (_, { dealerId }) => dealerId,
  (usersByCustomerId, dealerId) =>
    usersByCustomerId.find((user) => Number(user.dealerId) === Number(dealerId))
      ?.authToken
);

export const selectDefaultCustomerId = createSelector(
  selectUsersByEmail,
  (usersByEmail) =>
    Maybe.fromNullable(usersByEmail.first())
      .map((user) => toInt(user.get("accessibleId")))
      .getOrElse(null)
);

export const selectDefaultAuthToken = createSelector(
  selectUsersByCustomerId,
  selectActiveSystem,
  selectDefaultSystem,
  selectSelectedCustomerIdForAdminSection,
  selectDefaultCustomerId,

  (
    usersByCustomerId,
    activeSystem,
    defaultSystem,
    selectedCustomerForAdminSection,
    defaultCustomerId
  ) => {
    switch (true) {
      case activeSystem && usersByCustomerId.has(activeSystem.customerId):
        return usersByCustomerId.getIn([activeSystem.customerId, "authToken"]);
      case selectedCustomerForAdminSection &&
        usersByCustomerId.has(selectedCustomerForAdminSection):
        return usersByCustomerId.getIn([
          selectedCustomerForAdminSection,
          "authToken",
        ]);
      case !!sessionStorage.getItem("siteAccessibleId"):
        return usersByCustomerId.getIn([
          Number(sessionStorage.getItem("siteAccessibleId")),
          "authToken",
        ]);
      case defaultSystem && usersByCustomerId.has(defaultSystem.customerId):
        return usersByCustomerId.getIn([defaultSystem.customerId, "authToken"]);
      case !!defaultCustomerId:
        return usersByCustomerId.getIn([defaultCustomerId, "authToken"]);
      default:
        return undefined;
    }
  }
);

export const selectUsersByAuthToken = createSelector(
  selectUsersByCustomerId,
  (usersByCustomerId) => usersByCustomerId.mapKeys((_, user) => user.authToken)
);
export const selectActiveUser = createSelector(
  selectUsersByAuthToken,
  selectDefaultAuthToken,
  (usersByAuthToken, activeAuthToken) => usersByAuthToken.get(activeAuthToken)
);
export const selectIsTempDealerUser = compose(
  getOrElse(false),
  map(compose(prop("isJust"), immutableGet("dealerTempExpires"))),
  selectUser
);
export const selectLocationBeforeTransitions = compose(
  prop("locationBeforeTransitions"),
  immutableGet("route")
);
export const selectCustomerNamesBySystemId = createSelector(
  selectSystems,
  selectUsersByCustomerId,
  (systems, usersByCustomerId) =>
    systems.map((system) =>
      usersByCustomerId.getIn([system.customerId, "customerName"])
    )
);
export const selectDealersByCustomerId = createSelector(
  selectDealerDomain,
  selectUsersByCustomerId,
  (dealerDomain, usersByCustomerId) =>
    dealerDomain.byId.map((dealersById) =>
      usersByCustomerId.map((user) => dealersById.get(user.dealerId))
    )
);
export const selectDealersBySystemId = createSelector(
  selectSystems,
  selectDealersByCustomerId,
  (systems, maybeDealersByCustomerId) =>
    systems.map((system) =>
      maybeDealersByCustomerId
        .map((dealersByCustomerId) =>
          dealersByCustomerId.get(system.customerId)
        )
        .getOrElse(null)
    )
);
export const selectCustomerIds = createSelector(
  selectUsersByCustomerId,
  (usersByCustomerId) => usersByCustomerId.keySeq().toSet()
);
export const selectDealerIds = createSelector(
  selectUsersByCustomerId,
  (usersByCustomerId) =>
    usersByCustomerId.valueSeq().map(prop("dealerId")).toSet()
);
export const selectUserForActiveSystem = createSelector(
  selectUsersByCustomerId,
  selectActiveSystem,
  (usersByCustomerId, activeSystem) =>
    activeSystem && usersByCustomerId.get(activeSystem.customerId)
);
